<template>
  <product title="Amazfit T-Rex"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="799"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    :video="video"
    buy-link="https://j.youzan.com/bu1zP9"
  ></product>
</template>

<script>
import { CDN_URL } from '@/config'
import Product from '../Product'

export default {
  name: 't-rex',
  components: {
    Product
  },
  data () {
    return {
      slogan: '12项军规认证 | 户外风格设计 | 20天超长续航',
      colors: [
        '枪灰',
        '岩黑',
        '军绿',
        '迷彩',
        '卡其'
      ],
      video: {
        url: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/videos/t-rex.mp4',
        poster: CDN_URL + '/images/poster/t-rex.png'
      },
      swiperData: [
        [
          CDN_URL + '/images/product/t-rex/swiper/1.png',
          CDN_URL + '/images/product/t-rex/swiper/2.png',
          CDN_URL + '/images/product/t-rex/swiper/3.png',
          CDN_URL + '/images/product/t-rex/swiper/4.png'
        ],
        [
          CDN_URL + '/images/product/t-rex/swiper/5.png',
          CDN_URL + '/images/product/t-rex/swiper/6.png',
          CDN_URL + '/images/product/t-rex/swiper/7.png',
          CDN_URL + '/images/product/t-rex/swiper/8.png'
        ],
        [
          CDN_URL + '/images/product/t-rex/swiper/9.png',
          CDN_URL + '/images/product/t-rex/swiper/10.png',
          CDN_URL + '/images/product/t-rex/swiper/11.png',
          CDN_URL + '/images/product/t-rex/swiper/12.png'
        ],
        [
          CDN_URL + '/images/product/t-rex/swiper/13.png',
          CDN_URL + '/images/product/t-rex/swiper/14.png',
          CDN_URL + '/images/product/t-rex/swiper/15.png',
          CDN_URL + '/images/product/t-rex/swiper/16.png'
        ],
        [
          CDN_URL + '/images/product/t-rex/swiper/17.png',
          CDN_URL + '/images/product/t-rex/swiper/18.png',
          CDN_URL + '/images/product/t-rex/swiper/19.png',
          CDN_URL + '/images/product/t-rex/swiper/20.png'
        ]
      ],
      relativedProducts: [
      ],
      detailImages: [
        CDN_URL + '/images/product/t-rex/detail/01w.jpg',
        CDN_URL + '/images/product/t-rex/detail/02.jpg',
        CDN_URL + '/images/product/t-rex/detail/03.jpg',
        CDN_URL + '/images/product/t-rex/detail/04.jpg',
        CDN_URL + '/images/product/t-rex/detail/05.jpg',
        CDN_URL + '/images/product/t-rex/detail/06.jpg',
        CDN_URL + '/images/product/t-rex/detail/07.jpg',
        CDN_URL + '/images/product/t-rex/detail/08.jpg',
        CDN_URL + '/images/product/t-rex/detail/09.jpg',
        CDN_URL + '/images/common/zepp-black.jpg',
        CDN_URL + '/images/product/t-rex/detail/10.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/t-rex/detail/11.jpg'
      ]
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
